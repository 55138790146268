$(function(){
   
    $( document ).on('turbolinks:load', function() {  
        $( ".send-mail" ).click(function() {
            alert('AA')
        });

        $("header nav ul li").on({
            mouseenter: function () {
                $('header').css('width', 200);
                $('header ul').css('text-align', "left");
                $('header ul li').css('padding-left', 30);
                $('header nav ul li span').css('display','inline-block');
                $('.logout').css('left', 30);
            },
            mouseleave: function () {
                $('header').css('width', 60);
                $('header ul').css('text-align', "center");
                $('header ul li').css('padding-left', 0);
                $('.logout').css('left', 20);
                $('header nav ul li span').css('display','none');
            }
        });
        
        $(window).scroll(function() {
            if($(window).scrollTop() + $(window).height() > $(document).height() - 100) {
                $('.fa-arrow-alt-circle-up').css('right', "50px");
            }else{
                $('.fa-arrow-alt-circle-up').css('right', "-150px");
            }
        });

        $( ".fa-arrow-alt-circle-up" ).click(function() {
            $('html,body').animate({
                scrollTop: $('body').offset().top
            });
            $( ".relations a" ).removeClass('active');
        });

        $( ".relations a" ).click(function(e) {
            e.preventDefault();
            $( ".relations a" ).removeClass('active');
            var link = $(this).attr('href');
            $(this).addClass('active');
            $('html,body').animate({
                scrollTop: $(link).offset().top
            });
        });     
       
        $('.card-show #input').on({
            focus: function() {
                if (!$(this).data('disabled')) this.blur()
            },
            dblclick: function() {
                $(this).data('disabled', true);
                this.focus();
                $(this).parent('.input-content').find('.btn-save-input').show();
            },
            blur: function() {
                $(this).data('disabled', false);
            }
        });

        $( ".btn-save-input.exit" ).click(function(e) {
            $(this).parent('.input-content').find('.btn-save-input').hide();
        }); 


        $(".btn-reset-filters" ).click(function(e) {
            e.preventDefault();
            $('.filters form').trigger("reset");
        });
       

        $(".btn-filters-table" ).click(function(e) {
            e.preventDefault();
            $('.filters').slideToggle();
            if($('.btn-filters-table').text() == 'Filters'){
                $('.btn-filters-table').text("Close Filters");
            }else{
                $('.btn-filters-table').text("Filters");
            }
        });


        $(".open-mail" ).click(function(e) {
            e.preventDefault();
            $('.section-mail').slideToggle();
            if($('.open-mail').text() == 'Open Email'){
                country = $('select.country').find(":selected").val(); //this.value;   
                console.log(country);         
                $.ajax({
                    url : '/get-poker-room',
                    type : 'GET',
                    data : {
                        country: country
                    },
                    dataType:'json',
                    success : function(data) {  
                        console.log(data);   
                        select_proom(data, "1");
                    },
                    error : function(request,error)
                    {
                        console.log("Request: "+JSON.stringify(request));
                    }
                });
                $('.open-mail').text("Close Email");
            }else{
                $('.open-mail').text("Open Email");
            }
        });

         $( ".btn-save-input.save" ).click(function(e) {
        
            var data_id = ""; 
            var entity = ""; 
            var value = ""; 
            var input_name = ""; 

            var data_id = $(this).parent('.input-content').attr('data-id');
            var entity = $(this).parent('.input-content').attr('data-entity');
            var value = $(this).parent('.input-content').find('#input').val();
            var input_name = $(this).parent('.input-content').find('#input').attr('name');

            $.ajax({
                url : '/admin/save-entity',
                type : 'POST',
                data : {
                    data_id: data_id, entity: entity, value: value, input_name: input_name
                },
                dataType:'json',
                success : function() {
                    location.reload();
                },
                error : function(request,error)
                {
                    console.log("Request: "+JSON.stringify(request));
                }
            });
        }); 

        $('select.proom, select.country').prop("disabled", false);

        // $( ".section-mail .type" ).on('change', function() {
        //     var type = this.value;
        //     $('select.proom, select.broker, select.country').prop("disabled", false);
        //     if(type == "Broker"){
        //         $('select.proom, select.country').prop("disabled", true);
        //         $('select.broker').prop("disabled", false);
        //         $('.btn-send-email').attr('data-type', 'broker');
        //     }else if(type == "Poker Room"){
        //         $('select.proom, select.country').prop("disabled", false);
        //         $('select.broker').prop("disabled", true);
        //         $('.btn-send-email').attr('data-type', 'proom');
        //     }else{
        //         $('select.proom, select.broker, select.country').prop("disabled", true);
        //         $('.btn-send-email').removeAttr('data-type');
        //     }
        // });


        $( "select#form" ).on('change', function() {
        
            var data_id = ""; 
            var entity = ""; 
            var value = ""; 
            var input_name = ""; 

            var data_id = $(this).attr('data-id');
            var entity = $(this).attr('data-entity');
            var value = this.value;
            var input_name = $(this).attr('name');

            $.ajax({
                url : '/admin/save-entity',
                type : 'POST',
                data : {
                    data_id: data_id, entity: entity, value: value, input_name: input_name
                },
                dataType:'json',
                success : function() {
                    location.reload();
                },
                error : function(request,error)
                {
                    console.log("Request: "+JSON.stringify(request));
                }
            });
        });


        $(".btn-send-email" ).click(function(e) {
            e.preventDefault();
            email = $('.email').val();
            firstname = $('.firstname').val();
            lastname = $('.lastname').val();
            // type = $(this).attr('data-type');
            type = 'proom';

            console.log(type);

            if(type == 'proom'){
                select = $('select.proom').find(":selected").val();

                $.ajax({
                    url : '/send-email',
                    type : 'GET',
                    data : {
                        proom: select, email: email, firstname: firstname, lastname: lastname
                    },
                    dataType:'json',
                    success : function(data) { 
                        console.log('repsonse' + data );
                        if(data[0]['status']==200) {
                            $('p.notice').html('Email inviata con successo!');
                            $('p.notice').delay(2000).fadeOut();
                            setTimeout(function() { 
                                $('.section-mail').slideToggle();
                                $('.open-mail').text("Open Email");
                                location.reload();
                            }, 2000);
                        }        
                    },
                    error : function(request,error)
                    {
                        console.log("Request: "+JSON.stringify(request));
                    }
                });
            }

            // if(type == 'broker'){
            //     select = $('select.broker').find(":selected").val();
            //     $.ajax({
            //         url : '/send-email-trademagia',
            //         type : 'GET',
            //         data : {
            //             broker: select, email: email, firstname: firstname, lastname: lastname
            //         },
            //         dataType:'json',
            //         success : function(data) {     
            //             if(data[0]['status']==200) {
            //                 $('p.notice-trademagia').html('Email inviata con successo!');
            //                 $('p.notice-trademagia').delay(2000).fadeOut();
            //                 setTimeout(function() { 
            //                     location.reload();
            //                 }, 2000);
            //             }        
            //         },
            //         error : function(request,error)
            //         {
            //             console.log("Request: "+JSON.stringify(request));
            //         }
            //     });

            // }
            
        });
    
        $('select.country').on('change', function() {
            country = this.value;            
            $.ajax({
                url : '/get-poker-room',
                type : 'GET',
                data : {
                    country: country
                },
                dataType:'json',
                success : function(data) {  
                    console.log(data);   
                    select_proom(data, "1");
                },
                error : function(request,error)
                {
                    console.log("Request: "+JSON.stringify(request));
                }
            });    
        });

        $('select.country2').on('change', function() {
            country = this.value;  
            console.log(country);
            if (country == 'ITA') country = 'IT'
            if (country == 'BRA') country = 'BR'
            if (country == 'ESP') country = 'ES'

            $.ajax({
                url : '/get-poker-room',
                type : 'GET',
                data : {
                    country: country
                },
                dataType:'json',
                success : function(data) {  
                    console.log(data);   
                    select_proom(data, "2");
                },
                error : function(request,error)
                {
                    console.log("Request: "+JSON.stringify(request));
                }
            });    
        });

        $('.refer-card').hide();

        $('.show-refer-cards-btn').on('click', function(e) {
            e.preventDefault();
            
            var btn_text = $('.show-refer-cards-btn').text();
            console.log(btn_text);
            if (btn_text == 'Show Refer Cards') {
                $('.refer-card').show();
                $('.show-refer-cards-btn').text('Hide Refer Cards');
            } else {
                $('.refer-card').hide();
                $('.show-refer-cards-btn').text('Show Refer Cards');
            }
        });
    
        $('select.amount').on('change', function() {
            amount = this.value;
            if (amount == "99") {
                $('.custom_amount').prop('hidden', false)
            } else {
                $('.custom_amount').prop('hidden', true)
            }
        });

        $('select.status_report_email').on('change', function() {
            var status_report_email = this.value;
            var currentRow=$(this).closest("tr"); 
            var report_email_id=currentRow.find("input:hidden[name=report_email_id]").val();
            console.log("status_report_email= " + status_report_email);
            console.log("report_email_id= " + report_email_id);
            $.ajax({
                url : '/admin/upd-custom-st-re',
                type : 'POST',
                data : {
                    report_email_id: report_email_id, 
                    status_report_email: status_report_email
                },
                dataType:'json',
                success : function() {
                    location.reload();
                },
                error : function(request,error)
                {
                    console.log("Request: "+JSON.stringify(request));
                }
            });
        });

        $('select.status_player_account').on('change', function() {
            var status_player_account = this.value;
            var currentRow=$(this).closest("tr"); 
            var pa_id=currentRow.find("input:hidden[name=pa_id]").val();
            console.log("status_player_account= " + status_player_account);
            console.log("pa_id= " + pa_id);
            $.ajax({
                url : '/admin/upd-custom-st-pa',
                type : 'POST',
                data : {
                    pa_id: pa_id, 
                    status_player_account: status_player_account
                },
                dataType:'json',
                success : function() {
                    location.reload();
                },
                error : function(request,error)
                {
                    console.log("Request: "+JSON.stringify(request));
                }
            });
        });

        $('.btn_abilita').on('click', function(e) {
            e.preventDefault();
            var currentRow=$(this).closest("tr"); 
            var crm_email = currentRow.find("td[name=crm_email]").text();
            var crm_id = currentRow.find("td[name=crm_id]").text();
            console.log("crm_email= " + crm_email);
            console.log("crm_id= " + crm_id);
            if (confirm("Sicuro di volere abilitare l'utente CRM' '" + crm_email + "' su FormaLMS?")) {
                $.ajax({
                    url : '/forma_lms_users/activate_user',
                    type : 'POST',
                    data : {
                        crm_email: crm_email,
                        crm_id: crm_id
                    },
                    dataType:'json',
                    success : function(data) {  
                        console.log(data);   
                        if (data.success == 0) {
                            alert(data.msg);
                            location.reload();
                        } else {
                            alert(data.msg);
                        }
                    },
                    error : function(request,error)
                    {
                        console.log("Request: "+JSON.stringify(request));
                    }
                });
            }
        });

        $('.btn_riabilita').on('click', function(e) {
            e.preventDefault();
            var currentRow=$(this).closest("tr"); 
            var idst = currentRow.find("td[name=idst]").text();
            var userid = currentRow.find("td[name=userid]").text();
            console.log("idst: " + idst);
            console.log("userid: " + userid);

            if (confirm("Sicuro di volere riabilitare la UserId '" + userid + "' su FormaLMS?")) {
                $.ajax({
                    url : '/forma_lms_users/enable_user',
                    type : 'POST',
                    data : {
                        idst: idst,
                        userid: userid
                    },
                    dataType:'json',
                    success : function(data) {  
                        console.log(data);   
                        if (data.success) {
                            alert(data.msg);
                            location.reload();
                        } else {
                            alert(data.msg);
                        }
                    },
                    error : function(request,error)
                    {
                        console.log("Request: "+JSON.stringify(request));
                    }
                });
            }

        });

        $('.btn_disabilita').on('click', function(e) {
            e.preventDefault();
            var currentRow=$(this).closest("tr"); 
            var idst = currentRow.find("td[name=idst]").text();
            var userid = currentRow.find("td[name=userid]").text();
            console.log("idst: " + idst);
            console.log("userid: " + userid);

            if (confirm("Sicuro di volere disabilitare la UserId '" + userid + "' su FormaLMS?")) {
                $.ajax({
                    url : '/forma_lms_users/disable_user',
                    type : 'POST',
                    data : {
                        idst: idst,
                        userid: userid
                    },
                    dataType:'json',
                    success : function(data) {  
                        console.log(data);   
                        if (data.success) {
                            alert(data.msg);
                            location.reload();
                        } else {
                            alert(data.msg);
                        }
                    },
                    error : function(request,error)
                    {
                        console.log("Request: "+JSON.stringify(request));
                    }
                });
            }
        });

        $('.chk_course').on('change', function(e) {
            var $checkbox = $(this); // Salva un riferimento alla checkbox
            var idst = $(this).attr('data-idst');
            var course_id = $(this).attr('data-course-id');
            var course_code = $(this).attr('data-course-code');

            console.log("idst: " + idst);
            console.log("course_id: " + course_id);
            console.log("course_code: " + course_code);

            if ($(this).is(':checked')) {
                //Iscrivere utente al corso
                $.ajax({
                    url : "/forma_lms_users/course_update_user_subscription",
                    type : "POST",
                    data : {
                        idst: idst,
                        course_id: course_id,
                        user_status: "completed"
                    },
                    dataType:"json",
                    success : function(data) {  
                        console.log(data);   
                        if (data.success) {
                            alert("Corso EXT concluso");
                            $checkbox.attr("disabled", "disabled"); // Usa il riferimento salvato
                        } else {
                            alert("Errore");
                        }
                    },
                    error : function(request,error)
                    {
                        console.log("Request: "+JSON.stringify(request));
                        $checkbox.removeAttr("disabled"); // Usa il riferimento salvato
                    }
                });
            }
        });

        $('#btn_new_users_formalms').on('click', function(e) {
            e.preventDefault();
            var form_lms = $(this).closest("form"); 
            var email = form_lms.find("#email_lms").val();
            console.log(email);
            
            $.ajax({
                url : '/forma_lms_users/new',
                type : 'POST',
                data : {
                    email: email
                },
                dataType:'json',
                success : function(data) {  
                    console.log(data);   
                    if (data.success == 0) {
                        alert(data.msg);
                        location.reload();
                    } else {
                        alert(data.msg);
                    }
                },
                error : function(request,error)
                {
                    console.log("Request: "+JSON.stringify(request));
                }
            });
        });
        
        function select_proom(data, type){
            if (type == "1") option = "<option>Choose Poker Room</option>";
            if (type == "2") option = "<option value=''>-Choose-</option>";
    
            $.each(data, function(key, obj){
                if (type == "1") option += "<option value='"+obj.name+"'>"+obj.name+"</option>";
                if (type == "2") option += "<option value='"+obj.id+"'>"+obj.name+"</option>";
            });
    
            $('select.proom')
                .find('option')
                .remove()
                .end()
                .append(option)
                .prop("disabled", false)
            ;
        }

    });

    
    
});
